import { Row, Col } from 'antd'
import './index.css'

const Cell = ({ title, children }) => (
	<div className="cell">
		<div className="cell-title">{title}</div>
		<div className="cell-content">{children}</div>
	</div>
)

const list = [
	{ title: '票据号码', key: 'bill_no' },
	{ title: '出票日期', key: 'dim_issue_day' },
	{ title: '票面金额（元）', key: 'bill_amount' },
	{ title: '承兑日期', key: 'dim_acpt_day' },
	{ title: '承兑人名称', key: 'acpt_name' },
	{ title: '票据到期日', key: 'expir_day' },
	{ title: '承兑人开户机构名', key: 'dim_acpt_branch_name' },
	{ title: '披露日期', key: 'show_date' },
	{ title: '出票人名称', key: 'issue_name' },
	{ title: '票据介质', key: 'media_type' },
	{ title: '备注', key: 'remark' },
	{ title: '统一社会信用代码', key: 'soccode' },
]

const AcceptanceInfo = ({ data = {} }) => (
	<>
		<div className="title">票据承兑信息</div>
		<Row gutter={10}>
			{list.map((item, i) => (
				<Col xs={24} sm={24} md={12} lg={12} xl={8} key={i}>
					<Cell title={item.title}>{data[item.key]}</Cell>
				</Col>
			))}
		</Row>
	</>
)

export default AcceptanceInfo
