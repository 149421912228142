import { Typography, Result } from 'antd'
import './index.css'

const { Link } = Typography

const tips = {}

const query = window.location.search.slice(1)

const params = {}

if (query) {
	const item = (query || '').split('&')

	item.forEach(v => {
		const _ = (v || '').split('=')
		params[_?.[0]] = decodeURI(_?.[1] || '')
	})
}

tips[300] = (
	<p>
		输入票据号码为<b>{params?.ticketNum}</b>, 请核对票据号码是否正确。
		如输入票据号码正确, 则该票据未披露，
		<Link
			href="http://www.pbc.gov.cn/tiaofasi/144941/3581332/4151022/index.html"
			target="_blank"
		>
			根据中国人民银行公告〔2020〕第19号（规范商业承兑汇票信息披露）
		</Link>
		，“五、金融机构办理商业承兑汇票的贴现、质押、保证等业务前，应当通过票据信息披露平台查询票据承兑信息，票据承兑信息不存在或者票面记载事项与承兑人披露的信息不一致的，金融机构不得办理票据贴现、质押、保证等业务。”
	</p>
)

tips[400] = <p>票据号错误</p>

tips[500] = <p>app_id错误</p>

const FailTips = ({ code }) =>
	Object.keys(tips).includes(`${code}`) ? (
		<div className="fail-tips">
			<Result status="warning" title="未查到票据信息" extra={tips[code]} />
		</div>
	) : null

export default FailTips
