import { Table, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import './index.css'

const columns = [
	{
		title: '披露信息时点日期',
		dataIndex: 'show_date',
		key: 'show_date',
		align: 'center',
	},
	{
		title: '承兑人开户机构名称',
		dataIndex: 'dim_acpt_branch_name',
		key: 'dim_acpt_branch_name',
	},
	{
		title: () => (
			<div>
				累计承兑发生额（元）
				<Tooltip title="累计承兑发生额是指当年累计承兑发生额，即当年1月1日至披露日上一月月末累计承兑的电子商业汇票总金额。收款人未签收的电子商业汇票不计入承兑人的累计承兑发生额内。">
					<QuestionCircleOutlined />
				</Tooltip>
			</div>
		),
		dataIndex: 'acpt_amount',
		key: 'acpt_amount',
		align: 'center',
	},
	{
		title: () => (
			<div>
				承兑余额（元）
				<Tooltip title="承兑余额是指截至披露日上一月月末，承兑人已承兑但未结清的电子商业汇票总金额。承兑余额统计既包括未到期电子商业汇票，也包括已到期但未结清的电子商业汇票。收款人未签收的电子商业汇票不计入承兑人的承兑余额内，自收款人签收日起计入承兑余额内。">
					<QuestionCircleOutlined />
				</Tooltip>
			</div>
		),
		dataIndex: 'acpt_over',
		key: 'acpt_over',
		align: 'center',
	},
	{
		title: () => (
			<div>
				累计逾期发生额（元）
				<Tooltip title="累计逾期发生额是指截至披露日上一月月末，近5年内发生过逾期的全部电子商业汇票总金额。">
					<QuestionCircleOutlined />
				</Tooltip>
			</div>
		),
		dataIndex: 'total_overdue_amount',
		key: 'total_overdue_amount',
		align: 'center',
	},
	{
		title: () => (
			<div>
				逾期余额（元）
				<Tooltip title="逾期余额是指截至披露日上一月月末，承兑人已逾期但未结清的电子商业汇票总金额。">
					<QuestionCircleOutlined />
				</Tooltip>
			</div>
		),
		dataIndex: 'overdue_over',
		key: 'overdue_over',
		align: 'center',
	},
	{
		title: '披露日期',
		dataIndex: 'rel_date',
		key: 'rel_date',
		align: 'center',
	},
	{
		title: '系统备注',
		dataIndex: 'remark',
		key: 'remark',
	},
	{
		title: '企业备注',
		dataIndex: 'ent_remark',
		key: 'ent_remark',
	},
]

const DelayedDisclosure = ({ name, data }) =>
	data?.length ? (
		<>
			<div className="title">企业披露详情</div>
			<Table
				columns={columns}
				dataSource={data.map((v, i) => ({ ...v, key: i }))}
				pagination={false}
				scroll={{ x: 1250 }}
				size="middle"
				bordered
			/>
		</>
	) : null

export default DelayedDisclosure
