import Header from './components/Header'
import Main from './components/Main'
import Footer from './components/Footer'
import WaterMark from 'watermark-component-for-react'
import './App.css'

function App() {
	return (
		<div className="App">
			<Header />
			<WaterMark
				content="数科公司"
				globalAlpha={0.15}
				font="20px Microsoft Yahei"
			>
				<Main />
				<Footer />
			</WaterMark>
		</div>
	)
}

export default App
