import React, { useState, useEffect } from 'react'
import { Skeleton, Steps } from 'antd'
import FailTips from './FailTips'
import AcceptanceInfo from './AcceptanceInfo'
import AcceptanceCreditInfo from './AcceptanceCreditInfo'
import Roster from './Roster'

const { Step } = Steps

// const baseUrls = {
// 	dev: '',
// 	test: 'http://172.16.10.51:80',
// 	prod: 'http://121.37.15.32:8080',
// }

// const baseUrl = baseUrls[process.env.REACT_APP_ENV]

const Main = () => {
	const [data, setData] = useState({})

	const [loading, setLoading] = useState(true)

	useEffect(() => {
		fetch(`./risk/pjxx/ticket_hit_detail/${window.location.search}`)
			.then(res => res.json())
			.then(res => setData(res))
			.catch(e => console.log(e))
			.finally(() => setLoading(false))
	}, [])

	const getStatus = data => (data.length ? 'error' : 'finish')

	const {
		code,
		query_date,
		ticket_num_info,
		is_overdue,
		acpt_name_info,
		delay_disclosure,
		holding_overdue,
		large_overdue,
	} = data

	return (
		<div className="main">
			{loading ? (
				<Skeleton active />
			) : (
				<>
					<FailTips code={code} />
					<div className="title">查询日期：{query_date}</div>
					{code === 200 && (
						<>
							<Steps>
								<Step status="finish" title="票据承兑信息" />
								<Step
									status={is_overdue ? 'error' : 'finish'}
									title="企业披露详情"
								/>
								<Step
									status={getStatus(delay_disclosure)}
									title="延迟披露名单"
								/>
								<Step
									status={getStatus(holding_overdue)}
									title="持续逾期名单"
								/>
								<Step status={getStatus(large_overdue)} title="大额逾期名单" />
							</Steps>
							<AcceptanceInfo data={ticket_num_info} />
							<AcceptanceCreditInfo data={acpt_name_info} />
							<Roster name="延迟披露" data={delay_disclosure} />
							<Roster name="持续逾期" data={holding_overdue} />
							<Roster name="大额逾期" data={large_overdue} />
						</>
					)}
				</>
			)}
		</div>
	)
}

export default Main
