import { Typography } from 'antd'
import './index.css'

const { Link } = Typography

const Footer = () => (
	<div className="footer">
		<p>
			服务热线：
			<Link>
				0757-88605375
			</Link>
		</p>
		<p>
			信息来源：
			<Link href="http://disclosure.shcpe.com.cn/?#/dashboard" target="_blank">
				上海票据交易所-票据信息披露平台
			</Link>
		</p>
		<p>
			披露政策：
			<Link
				href="http://www.pbc.gov.cn/tiaofasi/144941/3581332/4151022/index.html"
				target="_blank"
			>
				中国人民银行公告〔2020〕第19号（规范商业承兑汇票信息披露）
			</Link>
		</p>
	</div>
)

export default Footer
