import './index.css'
import logo from '../images/logo.png'

const Header = () => (
	<div className="header">
		<img className="logo" src={logo} alt="" />
		<span>—— 由数科公司提供科技服务</span>
	</div>
)

export default Header
