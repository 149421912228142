import { Typography, Table } from 'antd'
import './index.css'

const { Link } = Typography

const columns = [
	{
		title: '命中名单名称',
		dataIndex: 'hit_table_name',
		key: 'hit_table_name',
	},
	{
		title: '发布日期',
		dataIndex: 'update_time',
		key: 'update_time',
		width: 100,
		align: 'center',
		render: update_time => update_time.slice(0, 10),
	},
	{
		title: '是否历史记录',
		dataIndex: 'is_history',
		key: 'is_history',
		width: 105,
		align: 'center',
	},
	{
		title: '备注',
		dataIndex: 'remask',
		key: 'remask',
	},
	{
		title: '操作',
		dataIndex: 'url',
		key: 'url',
		width: 75,
		align: 'center',
		render: url => (
			<Link href={url} target="_blank">
				查看原文
			</Link>
		),
	},
]

const DelayedDisclosure = ({ name, data }) =>
	data?.length ? (
		<>
			<div className="title">{`${name}名单`}</div>
			<Table
				columns={columns}
				dataSource={data.map((v, i) => ({ ...v, key: i }))}
				pagination={false}
				size="middle"
				bordered
			/>
		</>
	) : null

export default DelayedDisclosure
